/* Built In Imports */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import parser from 'html-react-parser';

/* External Imports */
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box,
    chakra
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/**
 * @description Renders the RudrakshaBenefits Faq component
 * @param {Object} faqData - data
 * @param {string} region - Current User Region
 * @returns {ReactElement} Rudraksha Faq component
 */
const RudrakshaFaq = ({ faqData, region }) => {
  return (
    <>
      {region == 'uk' ? (
        <>
          <Box width={{ lg: '700px', md: '700px', base: '90%' }} m="20px auto">
            <Box textAlign="center" mb="20px">
              <LazyLoadImageComponent
                title="Decoration Image"
                alt="Decoration Image"
                src={`${config.staticPath}/d/46272/1642829780-rudraksha-titledecor.png`}
              />
              {/* <Image src="https://images.sadhguru.org/d/46272/1642829780-rudraksha-titledecor.png" /> */}
            </Box>
            {faqData.sectionContent.map((el, i) => {
              return (
                <Accordion
                  allowMultiple
                  outline="0"
                  m={{
                    base: '5px auto 10px auto',
                    lg: '5px auto 10px auto',
                    md: '5px auto 10px auto',
                  }}
                  width={{ base: '90%', md: '100%', lg: '100%' }}
                  borderTop="none"
                >
                  <AccordionItem borderBottom="none" borderTop="none">
                    <AccordionButton
                      bgColor="#410C03"
                      borderRadius="3px"
                      mb="2px"
                      fontFamily="FedraSansStd-medium, sans-serif"
                      fontSize="1.1rem"
                      borderColor="#ffffff"
                      pt="4"
                      pb="4"
                      color="#ffffff"
                      _hover={{ bgColor: '#DED5C3', color: '#28231E' }}
                      _focus={{ shadow: 'transparent' }}
                    >
                      <chakra.div flex="1" textAlign="left">
                        {el.title[0]?.faqTitle}
                      </chakra.div>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={1}>
                      {el.faqs.map(elchild => {
                        return (
                          <AccordionItem
                            borderBottomWidth="1px"
                            borderBottomColor="gray.400"
                            borderTop="none"
                            lineHeight="1.61"
                          >
                            <AccordionButton
                              pt="0.8rem"
                              pb="0.8rem"
                              pl="0"
                              pr="0"
                              fontFamily="FedraSansStd-medium, sans-serif"
                              fontSize="1.1rem"
                              borderTop="none"
                              _hover={{ background: 'none' }}
                              _focus={{ shadow: 'transparent' }}
                            >
                              <chakra.div flex="1" textAlign="left">
                                {elchild?.question}
                              </chakra.div>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                              pb={4}
                              fontSize="1.1rem"
                              fontFamily="FedraSansStd-book, sans-serif"
                              lineHeight="1.61"
                              className="faq"
                            >
                              {parser(elchild?.answer)}
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </Box>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default RudrakshaFaq;
