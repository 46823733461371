/* Built In Imports */
/* External Imports */
import axios from 'axios';

/* Internal Imports */
/* Components */
import config from '@config';
// import { tryGetPreviewData } from 'utils';

/* Services */
import { filterBuildUrls } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';
import { getLatestBuild } from './commonService';
// import { consoleLog } from '@components/Utility/Shared/SharedService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (level, url, notMatchText, section) => {
  const data = config.BUILD_URLS.YOGA?.enabled
    ? config.BUILD_URLS.YOGA
    : await getLatestBuild(
      process.env.NEXT_PUBLIC_BUILD_ID ||
      process.env.env_var_yoga_build_id ||
      process.env.env_var_build_id
    );
  let urlArr = filterBuildUrls(section || 'yoga', level, url, notMatchText, data);
  return urlArr ? urlArr : [];
};

export const getRudrakshaPageLandingPaths = async (
  level,
  url,
  notMatchText
) => {
  let data = {};
  if (process.env?.NEXT_PUBLIC_BUILD_ID) {
    data = await getLatestBuild(process.env.NEXT_PUBLIC_BUILD_ID);
  } else if (config.BUILD_URLS?.LANDING_PAGES?.RudrakshaDiksha) {
    data = await getLatestBuild(
      process.env.NEXT_PUBLIC_BUILD_ID || process.env.env_var_build_id
    );
  }
  let urlArr = filterBuildUrls('landing_pages', level, url, notMatchText, data);
  return urlArr || [];
};

export const fetchRudrakshaPageData = async (region, lang) => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: `rudraksha-diksha`,
        region,
        lang,
      },
    }
  );
  return pageData;
};

export const fetchRDGeoContentData = async region => {
  let apiurl = '';
  if (config.ENV_NAME === 'production') {
    apiurl = `https://iso-facade.sadhguru.org/content/geo-content?format=json&url=/${region}/en/rudraksha-diksha`;
  } else {
    apiurl = `https://uat-iso-facade.sadhguru.org/content/geo-content?format=json&url=/${region}/en/rudraksha-diksha`;
  }
  const GeocontentData = await axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    url: apiurl,
  });
  return GeocontentData?.data;
};

// yantra landing

export const fetchYantraPageData = async (region, lang) => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: `events/special-events/yantra-ceremony-with-sadhguru`,
        region,
        lang,
      },
    }
  );
  return pageData;
};

// Bio Insta Link page
export const fetchBiolinkPageData = async (region, lang) => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: `instagram-link-bio`,
        region,
        lang,
      },
    }
  );
  return pageData;
};