/* Built In Imports */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* External Imports */
import { Box, Grid, Image, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import RudrakshaGeoContent from '@components/UI/RudrakshaDiksha/RudrakshaGeoContent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';

/**
 * Renders Rudraksha Receives Component
 * @param {Object} sectionData - Data
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {Array} sectionData
 * @param {string} GeocontentData - Geo location user detail
 * @return {ReactElement} - Rudraksha Receives Component
 */

const RudrakshaReceives = ({
  sectionData,
  GeocontentData,
  region,
  language,
}) => {
  return (
    <>
      <Box
        bgImage={{
          base: `url(${sectionData[3]?.mobileImage.url})`,
          md: `url(${sectionData[3]?.image.url})`,
        }}
        backgroundSize="cover"
        backgroundPosition="center"
        padding={{ base: '50px 0', md: '70px 0' }}
        fontFamily="FedraSansStd-medium, sans-serif"
        textAlign="center"
      >
        <Text
          fontSize={{ base: '24px', md: '30px' }}
          color="#fff"
          maxWidth="528px"
          mx="auto"
          mb="10px"
          as="h2"
        >
          {sectionData[0].title[0].titleText}
        </Text>
        <Image
          title="Divider"
          alt="divider"
          src="https://static.ishaoutreach.org/static/assets/rudraksha-diksha/img/divider-1.svg"
          mb={{ base: '20px', md: '20px' }}
          w="18%"
        />
        <Text mt="0" mb={{ base: '40px', md: '70px' }} color="#ffffff">
          {sectionData[0].title[0].titleSubtext}
        </Text>
        <Box
          maxW="980px"
          display="block"
          mx="auto"
          textAlign="center"
          px={{ base: '0', md: '20px' }}
        >
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            gap={{ base: '40px', md: '70px' }}
            mb={{ base: '40px', md: '60px' }}
          >
            {sectionData[0]?.simpleCards.map((data, i) => {
              return (
                <Box
                  display={{ base: 'flex', md: 'block' }}
                  alignItems="center"
                  key={i}
                >
                  <Box display={{ base: 'flex', md: 'none' }}>
                    <LazyLoadImageComponent
                      src={data?.rectangularImage?.url}
                      alt={data?.rectangularImage?.alt}
                      title={data?.rectangularImage?.title}
                      width={{ base: '190px' }}
                    />
                  </Box>
                  <Box
                    width={{ base: '100%' }}
                    display={{ base: 'none', md: 'flex' }}
                    backgroundPosition="top, right, bottom, left"
                    backgroundRepeat="repeat-x, repeat-y"
                    backgroundSize="10px 1px, 1px 10px"
                    height="250px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      background={`url('${data?.rectangularImage?.url}')`}
                      height={{ base: '100px', md: '252px' }}
                      width="100%"
                      display="flex"
                      backgroundSize="contain"
                      backgroundRepeat="no-repeat"
                      backgroundPosition="center"
                    />
                  </Box>
                  <Box
                    color="#fff"
                    ml={{ base: '10px', md: '0' }}
                    textAlign={{ base: 'left', md: 'center' }}
                    pr={{ base: '25px', md: '0' }}
                  >
                    <Text
                      fontSize={{ base: '18px', md: '27px' }}
                      lineHeight="25px"
                      mb={{ base: '0', md: '10px' }}
                    >
                      {data.title}
                    </Text>
                    <Text
                      fontFamily="FedraSansStd-book"
                      color="#A0B9D9"
                      px={{ base: '0', md: '40px' }}
                      maxW={{ base: '420px' }}
                      lineHeight={{ base: '20px', md: '30px' }}
                      fontSize={{ base: '14px', md: '20px' }}
                    >
                      {data.descriptionText}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Image
            src={sectionData[1]?.image.url}
            title={sectionData[1]?.image.title}
            alt={sectionData[1]?.image.alt}
            mb="30px"
            w="25%"
          />
          <Text
            fontFamily="FedraSansStd-book"
            color="#fff"
            maxWidth="640px"
            mx="auto"
            lineHeight="30px"
            mb="30px"
            px={{ base: '25px', md: '0' }}
          >
            {StructuredTextContent(sectionData[2].body)}
          </Text>
          {region != 'global' ? (
            <>
              <Box>
                {sectionData[4]?.linkUrl !== '' ? (
                  <>
                    <Link
                      // href={sectionData[4]?.linkUrl}
                      href={refineUrlDomain(
                        sectionData[4]?.linkUrl,
                        region,
                        language
                      )}
                      target="_blank"
                      bgColor="#D68306"
                      borderRadius="4px"
                      display="inline-block"
                      fontFamily="FedraSansStd-medium, sans-serif"
                      fontSize={{ lg: '22px', base: '16px' }}
                      padding="9px 20px"
                      color="#fff"
                      _hover={{
                        textDecoration: 'none',
                      }}
                    >
                      {sectionData[4].buttonText}
                    </Link>
                  </>
                ) : (
                  <>
                    <Text
                      fontFamily="FedraSansStd-medium, sans-serif"
                      fontSize={{ lg: '22px', base: '16px' }}
                      padding="9px 20px"
                    >
                      {sectionData[4].buttonText}
                    </Text>
                  </>
                )}
              </Box>
              {sectionData[5] && sectionData[5]?.text != '' ? (
                <>
                  <Box textAlign="center" mt="18px">
                    <Text
                      fontSize="14px"
                      mb="0"
                      fontFamily="FedraSansStd-book"
                      color="#A0B9D9"
                    >
                      {sectionData[5].text}
                    </Text>
                  </Box>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <Box textAlign="center">
                <RudrakshaGeoContent
                  GeocontentData={GeocontentData}
                  typedata="button"
                  // linkurl={sectionData[4]?.linkUrl}
                  linkurl={refineUrlDomain(
                    sectionData[4]?.linkUrl,
                    region,
                    language
                  )}
                />
                <Box color="#A0B9D9">
                  <RudrakshaGeoContent
                    GeocontentData={GeocontentData}
                    typedata="text-below-button"
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RudrakshaReceives;
