/* Built In Imports */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';

/**
 * Renders Rudraksha Legend Component
 * @param {Array} sectionData - Rudraksha Legend Data
 * @return {ReactElement} Rudraksha Legend Component
 */
const RudrakshaLegend = ({ sectionData }) => {
  return (
    <>
      <Box
        bgColor="#f1eddb"
        padding={{ base: '60px 25px', md: '50px 60px' }}
        textAlign="center"
      >
        <Box maxW="800px" margin="0 auto">
          <Text
            fontSize="30px"
            color="#28231E"
            maxWidth="390px"
            fontFamily="FedraSansStd-medium, sans-serif"
            textAlign="center"
            mx="auto"
            mb="30px"
          >
            {sectionData[0].titleText}
          </Text>
          <Image
            src="https://static.ishaoutreach.org/static/assets/rudraksha-diksha/img/diskha-divider.svg"
            mb="50px"
            w="20%"
            alt='Divider'
            title='Divider'
          />
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Box
              flex={{ base: '230px', md: '0 0 300px' }}
              mb={{ base: '25px', md: '0' }}
            >
              <LazyLoadImageComponent src={sectionData[2]?.image?.url} />
            </Box>
            <Box
              color="#"
              fontSize={{ base: '16px', md: '18px' }}
              fontFamily="FedraSansStd-Book"
              textAlign="left"
              pl={{ base: '0', md: '60px' }}
            >
              {StructuredTextContent(sectionData[1].body)}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default RudrakshaLegend;
