/* Built In Imports */
import parser from 'html-react-parser';

/* External Imports */
import { Box, Image, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import RudrakshaGeoContent from '@components/UI/RudrakshaDiksha/RudrakshaGeoContent';
import { refineUrlDomain } from '@components/Utility/utils';

/**
 * Renders Rudraksha Meaning Component
 * @param {Array} sectionData - Data
 * @param {string} GeocontentData - User Geolocation details
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @return {ReactElement} Rudraksha Meaning Component
 */
const RudrakshaMeaning = ({
  sectionData,
  GeocontentData,
  region,
  language,
}) => {
  return (
    <>
      <Box bgColor="#f1eddb" fontFamily="FedraSansStd-medium">
        <Box
          maxW="690px"
          mx="auto"
          textAlign="center"
          padding={{ base: '40px 25px 50px', md: '60px 0' }}
        >
          <Text
            fontSize={{ base: '24px', md: '30px' }}
            mb={{ base: '20px', md: '20px' }}
            maxW={{ base: '300px', md: '100%' }}
            mx="auto"
            as="h2"
          >
            {sectionData[0].titleText}
          </Text>

          <Image
            src={sectionData[3].image.url}
            alt={sectionData[3].image.alt}
            title={sectionData[3].image.title}
            w="20%"
            mb={{ base: '30px', md: '40px' }}
          />

          <Box textAlign="left" fontFamily="FedraSansStd-book">
            <Text mb="25px" lineHeight="30px" className="paragraph-custom">
              {parser(
                sectionData[1].htmlContent ? sectionData[1].htmlContent : ''
              )}
            </Text>
          </Box>
          {region != 'global' ? (
            <>
              <Box>
                <Box textAlign="center">
                  {sectionData[4]?.linkUrl !== '' ? (
                    <>
                      <Link
                        // href={sectionData[4]?.linkUrl}
                        href={refineUrlDomain(
                          sectionData[4]?.linkUrl,
                          region,
                          language
                        )}
                        target="_blank"
                        bgColor="#D68306"
                        borderRadius="4px"
                        display="inline-block"
                        fontFamily="FedraSansStd-medium, sans-serif"
                        fontSize={{ lg: '22px', base: '16px' }}
                        padding="9px 20px"
                        color="#fff"
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        {sectionData[4]?.buttonText}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Text
                        fontFamily="FedraSansStd-medium, sans-serif"
                        fontSize={{ lg: '22px', base: '16px' }}
                        padding="9px 20px"
                      >
                        {sectionData[4]?.buttonText}
                      </Text>
                    </>
                  )}
                </Box>
                <Box mt="16px">
                  <Text fontSize="14px" mb="0" fontFamily="FedraSansStd-book">
                    {sectionData[5]?.text}
                  </Text>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box textAlign="center">
                <RudrakshaGeoContent
                  GeocontentData={GeocontentData}
                  typedata="button"
                  // linkurl={sectionData[4]?.linkUrl}
                  linkurl={refineUrlDomain(
                    sectionData[4]?.linkUrl,
                    region,
                    language
                  )}
                />
                <Box color="#000000">
                  <RudrakshaGeoContent
                    GeocontentData={GeocontentData}
                    typedata="text-below-button"
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
        <style global jsx>{`
          .paragraph-custom p {
            margin-bottom: 20px;
            color: #28231e;
          }
          .paragraph-custom ul {
            margin-left: 20px;
            line-height: 35px;
          }
        `}</style>
      </Box>
    </>
  );
};

export default RudrakshaMeaning;
