/* Built In Imports */
import parser from 'html-react-parser';

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import RudrakshaFaq from '@components/UI/RudrakshaDiksha/RudrakshaFaq';
import RudrakshaGeoContent from '@components/UI/RudrakshaDiksha/RudrakshaGeoContent';
import StructuredTextContent from '@components/UI/StructuredTextContent';

/**
 * Renders Rudraksha Footer component
 * @param {string} footerData - Footer data
 * @param {string} GeocontentData - Current Geo Location
 * @param {string} region - Current User Region
 * @param {string} faqData - Faq section Data
 * @return {ReactElement} RudrakshaFooter Component
 */
const RudrakshaFooter = ({ footerData, GeocontentData, region, faqData }) => {
  // consoleLog(footerData, 'footerData');
  return (
    <>
      <Box>
        {region != 'uk' ? (
          <>
            <Box
              fontFamily="FedraSansStd-medium, sans-serif"
              fontSize={{ base: '24px', md: '30px' }}
              textAlign="center"
              padding="30px"
              display="block"
              textDecor="underline"
              color="#cd6727"
              className="hyperlink"
            >
              {StructuredTextContent(footerData[0].body)}
            </Box>
          </>
        ) : (
          <>
            <Box
              color="#28231E"
              className="contactaddress"
              fontSize={{ base: '24px', md: '30px' }}
              fontFamily="FedraSansStd-medium, sans-serif"
              width={{ lg: '700px', md: '700px', base: '90%' }}
              m="50px auto 10px auto"
              textAlign="center"
            >
              {footerData[3]?.titleText}
            </Box>
            <RudrakshaFaq faqData={faqData} region={region} />
          </>
        )}
        <Flex flexDir={{ base: 'column' }} mt="50px">
          <Box
            padding={{
              lg: '30px calc((100% - 1140px) / 2)',
              base: '30px calc((100% - 340px) / 2)',
            }}
            bgColor="#907D69"
          >
            <Box width="100%" textAlign="center">
              {region != 'global' ? (
                <>
                  <Box
                    color="#ffffff"
                    className="contactaddress"
                    fontSize={{ base: '24px', md: '30px' }}
                    fontFamily="FedraSansStd-medium, sans-serif"
                  >
                    {footerData[1].titleText}
                  </Box>
                  <Box id="contactdetail" color="#000">
                    {parser(
                      footerData[2].htmlContent ? footerData[2].htmlContent : ''
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Box className="contactaddress">
                    <RudrakshaGeoContent
                      GeocontentData={GeocontentData}
                      typedata="contact"
                      titletxt={footerData[1].titleText}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Flex>
        <style jsx global>{`
          #contactdetail a,
          .contactaddress a,
          .hyperlink a {
            color: #ffa735;
          }
        `}</style>
      </Box>
    </>
  );
};

export default RudrakshaFooter;
