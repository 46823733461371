/* External Imports */
import { Box, Image, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import RudrakshaGeoContent from '@components/UI/RudrakshaDiksha/RudrakshaGeoContent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';

/**
 * Renders Rudraksha Section Component
 * @param {string} region - Current User Region
 * @param {language} region - Current User Language
 * @param {Array} sectionData - data
 * @param {string} GeocontentData - Geo location user detail
 * @returns {ReactElement} - Rudraksha Section Component
 */
const RudrakshaSection = ({
  sectionData,
  GeocontentData,
  region,
  language,
}) => {
  return (
    <>
      <Box bgColor="#f1eddb" fontFamily="FedraSansStd-medium">
        <Box
          maxW="690px"
          mx="auto"
          padding={{ base: '40px 25px 50px', md: '40px 0 50px' }}
        >
          <Box
            width={{ lg: '530px', base: '100%' }}
            fontFamily="FedraSansStd-book"
            fontSize={{ lg: '18px', md: '18px', base: '14px' }}
            lineHeight="30px"
            color="#28231E"
            m="0 auto 40px auto"
            className="introtxt"
          >
            {StructuredTextContent(sectionData[0].body)}
          </Box>
          {region != 'global' ? (
            <>
              <Box>
                <Box textAlign="center">
                  {sectionData[1]?.linkUrl != '' ||
                  sectionData[1]?.linkUrl != '#' ? (
                    <>
                      <Link
                        // href={sectionData[1]?.linkUrl}
                        href={refineUrlDomain(
                          sectionData[1]?.linkUrl,
                          region,
                          language
                        )}
                        target="_blank"
                        bgColor="#D68306"
                        borderRadius="4px"
                        display="inline-block"
                        fontFamily="FedraSansStd-medium, sans-serif"
                        fontSize={{ lg: '22px', base: '16px' }}
                        padding="9px 20px"
                        color="#fff"
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        {sectionData[1]?.buttonText}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Text
                        fontFamily="FedraSansStd-medium, sans-serif"
                        fontSize={{ lg: '22px', base: '16px' }}
                        padding="9px 20px"
                      >
                        {sectionData[1]?.buttonText}
                      </Text>
                    </>
                  )}
                </Box>
                {sectionData[7] && sectionData[7]?.text != '' ? (
                  <>
                    <Box textAlign="center" mb="15px">
                      <Text
                        fontSize="22px"
                        fontWeight="bold"
                        mb="0"
                        fontFamily="FedraSansStd-book"
                      >
                        {sectionData[7].text}
                      </Text>
                    </Box>
                  </>
                ) : (
                  ''
                )}
                <Box textAlign="center">
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    mb="0"
                    fontFamily="FedraSansStd-book"
                  >
                    {sectionData[6]?.text}
                  </Text>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box textAlign="center">
                <RudrakshaGeoContent
                  GeocontentData={GeocontentData}
                  typedata="button"
                  // linkurl={sectionData[1]?.linkUrl}
                  linkurl={refineUrlDomain(
                    sectionData[1]?.linkUrl,
                    region,
                    language
                  )}
                />
                {/* <Box mt="16px">
                <Text fontSize="14px" mb="0">
                  {sectionData[5]?.text}
                </Text>
              </Box> */}
                <Box color="#000000">
                  <RudrakshaGeoContent
                    GeocontentData={GeocontentData}
                    typedata="text-below-button"
                  />
                </Box>
              </Box>
            </>
          )}
          <Box textAlign="center" pt="24px">
            <Image
              src={sectionData[3]?.image?.url}
              alt={sectionData[3]?.image?.alt}
              title={sectionData[3]?.image?.title}
              mt="20px"
              w="20%"
            />
          </Box>
          <Box
            fontSize={{ base: '24px', md: '30px' }}
            color="#28231E"
            textAlign="center"
            pt="24px"
            m="0 auto"
            width={{ lg: '80%', base: '100%' }}
          >
            {sectionData[2].titleText}
          </Box>
          <Box textAlign="center" mt="30px">
            <Text fontFamily="FedraSansStd-book">
              {sectionData[2].titleSubtext}
            </Text>
          </Box>
          <Text as="p" textAlign="center" fontFamily="FedraSansStd-book">
            {sectionData[4]?.text}
          </Text>
          <Text as="p" textAlign="center">
            <Image
              src={sectionData[3]?.mobileImage?.url}
              alt={sectionData[3]?.mobileImage?.alt}
              title={sectionData[3]?.mobileImage?.title}
              mt="20px"
              mb="20px"
            />
          </Text>
          <Text as="p" textAlign="center" fontFamily="FedraSansStd-book">
            {sectionData[5]?.text}
          </Text>
          {/* <Flex
            bgColor="#fff"
            flexDirection="row"
            padding={{ base: '25px 20px', md: '25px 40px' }}
            borderRadius="10px"
            fontFamily="FedraSansStd-book"
            alignItems="center"
          >
            <Image
              width={{ base: '82px', md: '115px' }}
              src={`${config.staticPath}/assets/images/rudraksha/sadghuru.png`}
              borderRadius="50%"
              mr={{ base: '20px', md: '30px' }}
            />
            <Box fontSize="20px">
              <Text mb="20px">
                “Doing sadhana means trying to become an invitation to the
                Divine."
              </Text>
              <Text fontFamily="FedraSansStd-medium">- Sadhguru</Text>
            </Box>
          </Flex> */}
        </Box>
        <style jsx global>{`
          .introtxt p {
            padding-bottom: 10px;
          }
        `}</style>
      </Box>
    </>
  );
};

// const StickyButton = () => {
//   return (
//     <>
//       <Box id="stickyContainer">
//         <Box id="stickyWrapper">
//           <Box
//             display={{ base: 'flex', lg: 'none' }}
//             // justifyContent="space-around"
//             id="stickyTitleSection"
//             bgColor="#F5F0E5"
//             zIndex="1"
//             w={{ base: '100%', sm: '81%' }}
//             left={{ base: 'unset', sm: '9%' }}
//             border="2px solid red"
//           >
//             Button
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// };

export default RudrakshaSection;
