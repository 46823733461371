/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/**
 * Renders Rudraksha Header Component
 * @param {Object} headerData - Data
 * @returns {ReactElement} Rudraksha Header component
 */

const RudrakshaHeader = ({ headerData }) => {
  // consoleLog(headerData, "PPP")
  return (
    <>
      <Box className="RudrakshaPage">
        <Box
          className="backhome"
          fontFamily="FedraSansStd-book"
          bgColor="#010717"
          color="#ffffff"
          opacity="1"
          p={{ base: '10px 25px', md: '10px 30px' }}
        >
          <a href="/" fontSize="14px">
            <Image
              src={`${config.staticPath}/d/46272/1637315968-leftarrow.png`}
              alt="arrow"
              title="Arrow"
              // src="https://www.datocms-assets.com/46272/1637315968-leftarrow.png"
              mr="2"
            />
            Back to Home
          </a>
        </Box>
      </Box>
      <Box
        bgImage={{
          base: `url('${headerData[0]?.mobileImage?.url}')`,
          md: `url('${headerData[0]?.image?.url}')`,
        }}
        padding={{ base: '20px 30px', md: '60px 30px' }}
        backgroundSize="cover"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundPosition="center"
      >
        <Box>
          <Image
            src={headerData[2]?.image?.url}
            title={headerData[2]?.image?.title}
            alt={headerData[2]?.image?.alt}
            width={{ base: '120px', md: '320px' }}
            mb="60px"
          />
          <Box
            width={{ base: '75%', md: '100%', lg: '100%' }}
            m="0 auto"
            textAlign="center"
          >
            <Text
              color="#fff"
              fontFamily="FedraSansStd-book"
              fontWeight="200"
              fontSize={{ base: '16px', md: '22px', lg: '22px' }}
              textAlign="center"
              lineHeight={{ lg: '34px', base: '26px' }}
              m="0 auto"
            >
              {headerData[1].titleSubtext}
            </Text>
          </Box>
          <Box
            width={{ base: '100%', md: '100%', lg: '100%' }}
            m="0 auto"
            textAlign="center"
          >
            <Text
              fontSize={{ base: '28px', lg: '50px', md: '50px' }}
              color="#fff"
              as="h1"
              fontFamily="FedraSansStd-medium, sans-serif"
            >
              {headerData[1].titleText}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RudrakshaHeader;
