/* Built In Imports */
import React from 'react';

/* Built In Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */

/**
 * Renders Rudraksha Geo Content component
 * @param {string} GeocontentData - Current Geo Location
 * @param {string} titletxt -  Title
 * @param {string} linkurl - Link
 * @return {ReactElement} Rudraksha Geo Content Component
 */
const RudrakshaGeoContent = ({
  GeocontentData,
  typedata,
  titletxt,
  linkurl,
}) => {
  const renderButton = (txt, linkurl) => {
    return (
      <>
        {txt != '' ? (
          <>
            <Box>
              {linkurl != '' || linkurl != '#' ? (
                <>
                  <Link
                    href={`${linkurl}`}
                    target="_blank"
                    bgColor="#D68306"
                    borderRadius="4px"
                    display="inline-block"
                    fontFamily="FedraSansStd-medium, sans-serif"
                    fontSize={{ lg: '24px', base: '18px' }}
                    padding="9px 20px"
                    color="#ffffff"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    {txt}
                  </Link>
                </>
              ) : (
                <>
                  <Text
                    fontFamily="FedraSansStd-medium, sans-serif"
                    fontSize={{ lg: '22px', base: '16px' }}
                    padding="9px 20px"
                  >
                    {sectionData[4]?.buttonText}
                  </Text>
                </>
              )}
            </Box>
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  const renderContact = (txt, titletxt) => {
    return (
      <>
        {txt != '' ? (
          <>
            <Box className="contactaddress">
              <Box
                color="#ffffff"
                fontSize={{ base: '24px', md: '30px' }}
                fontFamily="FedraSansStd-medium, sans-serif"
              >
                {titletxt}
              </Box>
              {txt.includes('@') ? (
                <>
                  <Text color="#fff" fontSize={{ lg: '16px', base: '14px' }}>
                    <a href={`mailto:${txt}`}>{txt}</a>
                  </Text>
                </>
              ) : (
                <>
                  <Text color="#fff">
                    <a href={`${txt}`}>{txt}</a>
                  </Text>
                </>
              )}
            </Box>
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  const renderBelowtxt = txt => {
    return (
      <>
        {txt != '' ? (
          <>
            <Box textAlign="center" mb="15px">
              <Text fontSize="24px" mb="0" fontFamily="FedraSansStd-book">
                {txt}
              </Text>
            </Box>{' '}
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  return (
    <>
      <Box>
        {GeocontentData?.content?.map((data, i) => {
          return (
            <Box>
              {data.pageSection == 'button' && data.pageSection == typedata
                ? renderButton(data.content, linkurl)
                : data.pageSection == 'text-below-button' &&
                  data.pageSection == typedata
                ? renderBelowtxt(data.content)
                : data.pageSection == 'contact' && data.pageSection == typedata
                ? renderContact(data.content, titletxt)
                : ''}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default RudrakshaGeoContent;
