/* Built In Imports */
import React, { useEffect, useState } from 'react';

/* Internal Imports */
/* Components */
import RudrakshaBenefits from '@components/UI/RudrakshaDiksha/RudrakshaBenefits';
import RudrakshaFooter from '@components/UI/RudrakshaDiksha/RudrakshaFooter';
import RudrakshaHeader from '@components/UI/RudrakshaDiksha/RudrakshaHeader';
import RudrakshaLegend from '@components/UI/RudrakshaDiksha/RudrakshaLegend';
import RudrakshaMeaning from '@components/UI/RudrakshaDiksha/RudrakshaMeaning';
import RudrakshaMSR from '@components/UI/RudrakshaDiksha/RudrakshaMSR';
import RudrakshaReceives from '@components/UI/RudrakshaDiksha/RudrakshaReceives';
import RudrakshaSection from '@components/UI/RudrakshaDiksha/RudrakshaSection';
import HeadComponent from '@components/Utility/HeadComponent';
import { getLocale } from '@components/Utility/Shared/SharedService';
import config from '@config';
import WrapLayout from '@Layout/WrapLayout';

/* Services */
import { fetchFooterData } from 'services/commonService';
import {
    fetchRDGeoContentData,
    fetchRudrakshaPageData,
    getRudrakshaPageLandingPaths,
} from 'services/landingPageService';

/**
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Rudraksha Diksha Landing Page Data
 * @returns
 */
export default function RudrakshaDiksha({ region, language, pageData }) {
  const [show, setShow] = React.useState(false);
  const [GeocontentData, setGeocontentData] = useState([]);

  useEffect(async () => {
    localData();
  }, [region, language]);
  const localData = async () => {
    if (region == 'global') {
      const GeocontentData = await fetchRDGeoContentData(region, language);
      setGeocontentData(GeocontentData);
    }
  };
  const renderUIContent = () => {
    return pageData?.body?.map((section, i) => {
      if (section.sectionId == 'Faq') {
        const faqData = section;
      }
      switch (section.sectionId) {
        case uiSection.YANTRAHEADER:
          return (
            <RudrakshaHeader headerData={section.sectionContent} key={i} />
          );
        case uiSection.SECTION1:
          return (
            <RudrakshaSection
              sectionData={section.sectionContent}
              GeocontentData={GeocontentData ? GeocontentData : ''}
              region={region}
              language={language}
              key={i}
            />
          );
        case uiSection.BENEFITSRUDRAKSHA:
          return (
            <RudrakshaBenefits
              benefitsData={section.sectionContent}
              GeocontentData={GeocontentData ? GeocontentData : ''}
              region={region}
              language={language}
              key={i}
            />
          );
        case uiSection.RUDRAKSHAKAMEANING:
          return (
            <RudrakshaMeaning
              sectionData={section.sectionContent}
              GeocontentData={GeocontentData ? GeocontentData : ''}
              region={region}
              language={language}
              key={i}
            />
          );
        case uiSection.RUDRAKSHARECEIVES:
          return (
            <RudrakshaReceives
              sectionData={section.sectionContent}
              GeocontentData={GeocontentData ? GeocontentData : ''}
              region={region}
              key={i}
            />
          );
        case uiSection.RUDRAKSHALEGEND:
          return (
            <RudrakshaLegend sectionData={section.sectionContent} key={i} />
          );
        // case uiSection.RUDRAKSHASHARE:
        //   return (
        //     <RudrakshaShare sectionData={section.sectionContent} key={i} />
        //   );
        case uiSection.RUDRAKSHAMSR:
          return <RudrakshaMSR msrData={section.sectionContent} key={i} />;
        case uiSection.RIDRAKSHAFOOTER:
          return (
            <RudrakshaFooter
              footerData={section.sectionContent}
              GeocontentData={GeocontentData ? GeocontentData : ''}
              region={region}
              faqData={pageData.body.find(el => el.sectionId == 'Faq')}
              key={i}
            />
          );
        default:
          break;
      }
    });
  };

  const uiSection = {
    YANTRAHEADER: 'Header',
    SECTION1: 'Section-1',
    BENEFITSRUDRAKSHA: 'Section-2',
    RUDRAKSHAKAMEANING: 'Section-3',
    RUDRAKSHARECEIVES: 'Section-4',
    RUDRAKSHALEGEND: 'Section-5',
    RUDRAKSHASHARE: 'Secion-6',
    RUDRAKSHAMSR: 'MSRBanner',
    FAQ: 'Faq',
    RIDRAKSHAFOOTER: 'Section-7',
  };

  return (
    <WrapLayout>
      <HeadComponent
        data={pageData._seoMetaTags}
        availableLocals={pageData.availableLocales}
      ></HeadComponent>
      {renderUIContent()}
    </WrapLayout>
  );
}
export const getStaticPaths = async () => {
  let rudrakshaPaths = config.USE_LOCAL_URL
    ? []
    : await getRudrakshaPageLandingPaths('lvl0', '/rudraksha-diksha');

  return {
    paths: rudrakshaPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({ params: { region, language }, req }) => {
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchRudrakshaPageData(region, language);

  return {
    props: {
      region: region,
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      page: 'Rudraksha Diksha',
    },
  };
};
