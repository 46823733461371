/* External Imports */
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import RudrakshaGeoContent from '@components/UI/RudrakshaDiksha/RudrakshaGeoContent';
import { refineUrlDomain } from '@components/Utility/utils';

/**
 * @description Renders the RudrakshaBenefits component.
 * @param {Array}   benefitsData
 * @param {Object}   GeocontentData
 * @returns {ReactElement} RudrakshaBenefits component.
 */
const RudrakshaBenefits = ({
  benefitsData,
  GeocontentData,
  region,
  language,
}) => {
  return (
    <>
      <Box
        bgImage={{
          base: `url('${benefitsData[0]?.mobileImage?.url}')`,
          md: `url('${benefitsData[0]?.image?.url}')`,
        }}
        backgroundSize="cover"
        fontFamily="FedraSansStd-book"
      >
        <Box padding={{ base: '50px 25px', md: '50px 0 60px' }}>
          <Box display="flex">
            <Box ml={{ base: '0', md: '50%' }}>
              <Text
                fontSize={{ base: '24px', md: '30px' }}
                color="#fff"
                fontFamily="FedraSansStd-medium, sans-serif"
                mb="20px"
                maxW="400px"
                textAlign={{ base: 'center', md: 'left' }}
              >
                {benefitsData[1].titleText}
              </Text>
              <Image
                src={benefitsData[2]?.image?.url}
                alt={benefitsData[2]?.image?.alt}
                title={benefitsData[2]?.image?.title}
                position="relative"
                mb="30px"
              />
              <Box
                mb="60px"
                spacing={5}
                fontSize={{ base: '18px', md: '20px' }}
                color="#fff"
                fontFamily="Fedra-Light"
                fontWeight="normal"
              >
                <Flex color="#fff" mb="20px">
                  <Image
                    src={benefitsData[7]?.image?.url}
                    alt={benefitsData[7]?.image?.alt}
                    title={benefitsData[7]?.image?.title}
                    height="19px"
                    mt="4px"
                  />
                  <Box pl="10px"> {benefitsData[3].text}</Box>
                </Flex>
                <Flex color="#fff" mb="20px">
                  <Image
                    src={benefitsData[7]?.image?.url}
                    alt={benefitsData[7]?.image?.alt}
                    title={benefitsData[7]?.image?.title}
                    height="22px"
                    mt="4px"
                  />
                  <Box pl="10px"> {benefitsData[4].text}</Box>
                </Flex>
                <Flex color="#fff" mb="20px">
                  <Image
                    src={benefitsData[7]?.image?.url}
                    alt={benefitsData[7]?.image?.alt}
                    title={benefitsData[7]?.image?.title}
                    height="22px"
                    mt="4px"
                  />
                  <Box pl="10px"> {benefitsData[5].text}</Box>
                </Flex>
                <Flex color="#fff" mb="20px">
                  <Image
                    src={benefitsData[7]?.image?.url}
                    alt={benefitsData[7]?.image?.alt}
                    title={benefitsData[7]?.image?.title}
                    height="22px"
                    mt="4px"
                  />
                  <Box pl="10px"> {benefitsData[6].text}</Box>
                </Flex>
                {benefitsData[10] != undefined ? (
                  <>
                    <Flex color="#fff" mb="20px">
                      <Image
                        src={benefitsData[7]?.image?.url}
                        alt={benefitsData[7]?.image?.alt}
                        title={benefitsData[7]?.image?.title}
                        height="22px"
                        mt="4px"
                      />
                      <Box pl="10px">{benefitsData[10]?.text}</Box>
                    </Flex>
                  </>
                ) : (
                  ''
                )}
              </Box>
              {region != 'global' ? (
                <>
                  <Box>
                    <Box>
                      {benefitsData[8]?.linkUrl !== '' ? (
                        <>
                          <Link
                            // href={benefitsData[8]?.linkUrl}
                            href={refineUrlDomain(
                              benefitsData[8]?.linkUrl,
                              region,
                              language
                            )}
                            target="_blank"
                            bgColor="#D68306"
                            borderRadius="4px"
                            display="inline-block"
                            fontFamily="FedraSansStd-medium, sans-serif"
                            fontSize={{ lg: '22px', base: '16px' }}
                            padding="9px 20px"
                            color="#fff"
                            title=""
                            _hover={{
                              textDecoration: 'none',
                            }}
                          >
                            {benefitsData[8].buttonText}
                          </Link>
                        </>
                      ) : (
                        <>
                          <Text
                            fontFamily="FedraSansStd-medium, sans-serif"
                            fontSize={{ lg: '22px', base: '16px' }}
                            padding="9px 20px"
                            color="#ffffff"
                          >
                            {benefitsData[8].buttonText}
                          </Text>
                        </>
                      )}
                    </Box>
                    <Box mt="16px" color="#ffffff">
                      <Text fontSize="14px" mt="28px">
                        {benefitsData[9]?.text}
                      </Text>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box textAlign="left">
                    <RudrakshaGeoContent
                      GeocontentData={GeocontentData}
                      typedata="button"
                      // linkurl={benefitsData[8]?.linkUrl}
                      linkurl={refineUrlDomain(
                        benefitsData[8]?.linkUrl,
                        region,
                        language
                      )}
                    />
                    <Box color="#ffffff" width={{ lg: '80%', base: '100%' }}>
                      <RudrakshaGeoContent
                        GeocontentData={GeocontentData}
                        typedata="text-below-button"
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RudrakshaBenefits;
