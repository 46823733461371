/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';

/**
 * Renders Rudraksha MSR Component
 * @param {Array} msrDat
 * @returns {ReactElement} - Rudraksha MSR Component
 */
const RudrakshaMSR = ({ msrData }) => {
  return (
    <>
      <Box
        bgImage={{
          base: `url(${msrData[0].mobileImage.url})`,
          md: `url(${msrData[0].image.url})`,
        }}
        padding={{ base: '20px 25px', md: '100px 30px' }}
        backgroundSize="cover"
        height={{ base: '700px', md: 'auto' }}
        backgroundPosition={{ base: 'center', md: 'right center' }}
      >
        <Box maxW="990px" mx="auto">
          <Image
            src={msrData[1].image.url}
            alt={msrData[1].image.alt}
            title={msrData[1].image.title}
            width={{ base: '250px', md: '400px' }}
            mb="30px"
          />
          <Box maxW="450px" color="#FFA735" fontFamily="Fedra-Light" mb="30px">
            {StructuredTextContent(msrData[2].body)}
          </Box>
          <Text
            fontSize={{ base: '27px', md: '36px' }}
            color="#fff"
            fontFamily="FedraSansStd-medium"
            mb={{ lg: '20px', base: '10px' }}
          >
            {msrData[3].text}
          </Text>
          <Box
            fontFamily="FedraSansStd-book"
            className="custom-msrpara"
            textAlign={{ base: 'left', md: 'left' }}
          >
            {StructuredTextContent(msrData[4].body)}
          </Box>
        </Box>
        <style global jsx>{`
          .custom-msrpara {
            color: #ffa735;
          }
          .custom-msrpara a {
            color: #fff;
            text-decoration: underline;
          }
        `}</style>
      </Box>
    </>
  );
};

export default RudrakshaMSR;
